<template>
  <div class="action-block">
    <div class="common-action" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }} <span v-if="block.options.from && block.options.to">{{
        block.options.from }} - {{ block.options.to }}</span>
    </div>

    <vuestic-modal :isOpen="isShowModal" @cancel="isShowModal = false" :okShown="false" :cancelShown="false" :force="true"
      cancelClass="btn btn-danger" large>
      <span slot="title" class="text-primary font-weight-bold text-left">{{ block.name }}</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="waitWorkFlowForm">
        <form @submit.prevent="handleSubmit(save)" class="waitWorkFlowForm">
          <div class="row">
            <div class="col-12 mb-4">
              <text-input v-model="block.name"></text-input>
            </div>
            <div class="col-12 mb-4">
              <label>Timezone</label>
              <multiselect v-model="block.options.timezone" :options="timezones" :multiple="false" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true" placeholder="Select Timezone" label="label"
                track-by="label" :preselect-first="true" :show-labels="false" :allow-empty="false">
              </multiselect>
            </div>
            <div class="col-6">
              <div class="form-group">
                <div class="input-group">
                  <label class="control-label">From</label>
                  <vuestic-date-picker v-model="block.options.from" :config="timePickerConfig" />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <div class="input-group">
                  <label class="control-label">To</label>
                  <vuestic-date-picker v-model="block.options.to" :config="timePickerConfig" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4">
              <div class="control-label my-2 font-weight-bold">Advance window</div>
              <vuestic-switch v-model="block.options.IsActiveAdvance" :offcolor=true style="max-width: 200px">
                <span slot="trueTitle">ON</span>
                <span slot="falseTitle">OFF</span>
              </vuestic-switch>
            </div>
          </div>
          <div v-if="block.options.IsActiveAdvance">
            <div class="mt-3">
              <strong>Resume on</strong>
              <div class="mt-2">
                <button v-for="option in dayOptions" class="btn ml-1"
                  :class="{ 'btn-success': block.options.ResumeOn.includes(option.value), 'btn-outline-dark': !block.options.ResumeOn.includes(option.value) }"
                  type="button" :key="option.value" @click="handleSelectOption(option.value)">{{ option.label }}</button>
              </div>
            </div>
            <div class="mt-3">
              <div class="control-label my-2 font-weight-bold">Additional filter <a v-b-tooltip.hover title="Automation will be stopped unless this condition matches"
                  class="fa fa-question-circle tooltip-icon" /></div>
              <vuestic-switch v-model="block.options.IsActiveFilter" :offcolor=true style="max-width: 200px">
                <span slot="trueTitle">ON</span>
                <span slot="falseTitle">OFF</span>
              </vuestic-switch>
            </div>
            <div v-if="block.options.IsActiveFilter" class="mt-3">
              <div class="row mt-2">
                <div class="col-md-4">
                  <select v-model="block.options.Condition" class="form-control">
                    <option value="CurrentDay">Current Day of month</option>
                    <option value="CurrentMonth">Current month</option>
                    <option value="CurrentYear">Current year</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <select v-model="block.options.Operator" class="form-control">
                    <option value="Is">Is</option>
                    <option value="IsNot">Is not</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <text-input v-model="block.options.Statement" type="number" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary"
                :disabled="invalid || !block.options.from || !block.options.to || !block.options.timezone"
                style="min-width: 80px;">
                <span>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      default: null,
    },

    waitContacts: {
      type: Number,
      default: null,
    }
  },

  data() {
    return {
      dayOptions: [
        { value: 0, label: 'Sun' },
        { value: 1, label: 'Mon' },
        { value: 2, label: 'Tue' },
        { value: 3, label: 'Wed' },
        { value: 4, label: 'Thu' },
        { value: 5, label: 'Fri' },
        { value: 6, label: 'Sat' },
      ],
      processing: false,
      isShowModal: false,
      typeOptions: [
        { id: 'wait_for', label: 'Wait for' }
      ],
      typeSelected: null,
      periodOptions: [
        { id: 'seconds', label: 'second(s)' },
        { id: 'minutes', label: 'minute(s)' },
        { id: 'hours', label: 'hour(s)' },
        { id: 'days', label: 'day(s)' },
        { id: 'weeks', label: 'week(s)' },
        { id: 'months', label: 'month(s)' },
        { id: 'years', label: 'year(s)' },
      ],
      periodSelected: null,
      durationSelected: null,
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "G:i K",
      },
    }
  },

  computed: {
    timezones() {
      return this.$store.getters["timeZones"];
    },
  },

  mounted() {
    if (!this.block.options.ResumeOn) {
      this.block.options.ResumeOn = []
    }

    if (this.block.isNew) {
      this.block.isNew = false
      this.block.options.ResumeOn = [1,2,3,4,5]
      this.showModal()
    }
  },

  methods: {

    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    handleSelectOption(value) {
      if (this.block.options.ResumeOn.includes(value)) {
        this.block.options.ResumeOn = this.block.options.ResumeOn.filter(v => v !== value)
      } else {
        this.block.options.ResumeOn.push(value)
      }
    },
  }
}
</script>

    
<style lang="scss" scoped>
.branch {
  position: relative;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  border: 2px solid #ebebeb;
}

select {
  width: 100%;
  background: none !important;
  border: .0625rem solid #e7e7e7;
  border-radius: .3rem;
}
</style>
  